import React from 'react';
import { Result } from 'antd';
import styled from 'styled-components';

// Components
import { Translated } from '../../components/UI/Core';
import { Container } from '../../components/UI/Base';
import { Widget } from '../../components/UI/Widget/Widget';
import Checkmark from '../../assets/images/animations/checkmark.gif';

// Styled UI component
const StyledWidget = styled(Widget)`
  margin: 16px 0;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;

  & .ant-card-body {
    padding: 12px 16px 0;
  }
`;

export const InvitationAcceptedPage = () => (
  <Container style={{ height: '100%' }}>
    <StyledWidget>
      <Result
        status="success"
        icon={<img src={Checkmark} alt="checkmark" />}
        title={<Translated id="invitations.accepted.title" />}
        subTitle={<Translated id="invitations.accepted.description" />}
      />
    </StyledWidget>
  </Container>
);

// Types
import { OperationType } from '../../models/SignalRAction';
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  visitCreated,
  visitNotCreated,
  visitStatusNotUpdated,
  actionExecuted,
  actionNotExecuted,
  visitUpdatedInPacs,
} from './Visits.redux';

// Events
const SignalREvents = {
  visitCreated: `VisitCreatedIntegrationEvent`,
  ActionOperation: 'ActionOperationIntegrationEvent',
  PACSVisitorUpdatedIntegrationEvent: 'PACSVisitorUpdatedIntegrationEvent',
};

// Exceptions
const SignalRExceptions = {
  visitNotCreated: `VisitNotCreatedIntegrationException`,
  visitStatusNotUpdated: `VisitStatusNotUpdatedIntegrationException`,
  ActionOperationFailed: 'ActionOperationFailedIntegrationException',
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  hubConnection.on(SignalREvents.visitCreated, (msg) => {
    store.dispatch(visitCreated({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.visitNotCreated, (msg) => {
    store.dispatch(visitNotCreated({ history, msg }));
  });

  // Update Status
  hubConnection.on(SignalRExceptions.visitStatusNotUpdated, (msg) => {
    store.dispatch(visitStatusNotUpdated({ history, msg }));
  });
  hubConnection.on(SignalREvents.PACSVisitorUpdatedIntegrationEvent, (msg) => {
    store.dispatch(visitUpdatedInPacs({ history, msg }));
  });

  // Actions
  hubConnection.on(SignalREvents.ActionOperation, (msg) => {
    const op = msg.operation as OperationType;
    if (op === OperationType.Other) {
      store.dispatch(actionExecuted({ history, msg }));
    }
  });
  hubConnection.on(SignalRExceptions.ActionOperationFailed, (msg) => {
    const op = msg.operation as OperationType;
    if (op === OperationType.Other) {
      store.dispatch(actionNotExecuted({ history, msg }));
    }
  });
};

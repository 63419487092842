import React, { useState } from 'react';
import { Button, Col, Input, Row, Typography } from 'antd';
// Components
import styled from 'styled-components';
import { Flex } from '../UI/Base';
import { useData } from '../../hooks/App/useData';
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { Overlay } from '../../models/enums/Overlay';
import { useOverlay } from '../../hooks/Kiosk/useOverlay';

const { Title } = Typography;

// Styled
const PinPadLayover = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70%;
  width: 70%;
  border-radius: 12px;
  background: linear-gradient(to right, white 6px, transparent 6px) 0 0,
    linear-gradient(to right, white 6px, transparent 6px) 0 100%,
    linear-gradient(to left, white 6px, transparent 6px) 100% 0,
    linear-gradient(to left, white 6px, transparent 6px) 100% 100%,
    linear-gradient(to bottom, white 6px, transparent 6px) 0 0,
    linear-gradient(to bottom, white 6px, transparent 6px) 100% 0,
    linear-gradient(to top, white 6px, transparent 6px) 0 100%,
    linear-gradient(to top, white 6px, transparent 6px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  outline: 21rem solid rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

interface PinPadProps {
  onNext: () => void;
  onGoTo: (index: number) => void;
}

// Styled
const StyledInput = styled(Input)`
  height: 50px;
  margin-bottom: 25px;
  text-align: center;
  font-size: 25px;
`;
const StyledButton = styled(Button)`
  height: 75px;
  font-size: 1.6em;
`;

export const PinPad = ({ onNext, onGoTo }: PinPadProps) => {
  const [pin, setPin] = useState('');
  const { data: guest, fetch, pending } = useData(ApiEndpoints.guests.getByPinCode, null);
  const { status, setStatus, overlayResult } = useOverlay({ setPin, onNext, onGoTo, guest, pending });

  const dateTimeOffset = () => {
    const now = new Date();
    const timezoneOffset = now.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
    // Beginning of the day
    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    const startOfDayDateTimeOffset = new Date(startOfDay.getTime() - timezoneOffset).toISOString();

    // End of the day
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
    const endOfDayDateTimeOffset = new Date(endOfDay.getTime() - timezoneOffset).toISOString();

    return { startDate: startOfDayDateTimeOffset, endDate: endOfDayDateTimeOffset };
  };

  const handleButtonClick = (value: string) => {
    if (value === '<<') {
      setPin(pin.slice(0, -1));
    } else if (value === 'ENTER') {
      if (pin.length === 4) {
        const { startDate, endDate } = dateTimeOffset();
        fetch({ pin, startDate, endDate });
        setStatus(Overlay.Loading);
      }
    } else if (pin.length < 4) {
      setPin(pin + value);
    }
  };

  return (
    <>
      <Flex direction="column" alignItems="center" style={{ position: 'relative', zIndex: 1 }}>
        <Title style={{ marginBottom: '50px' }} level={2}>
          Pincode
        </Title>
        <Row gutter={[12, 12]}>
          <StyledInput size="large" value={pin} readOnly />
          {['1', '2', '3', '4', '5', '6', '7', '8', '9', '<<', '0', 'ENTER'].map((value) => (
            <Col span={8} key={value}>
              <StyledButton
                size="small"
                type={value === 'ENTER' ? 'primary' : 'default'}
                block
                onClick={() => handleButtonClick(value)}
                key={value}
              >
                {value}
              </StyledButton>
            </Col>
          ))}
        </Row>
      </Flex>

      {status !== Overlay.None && (
        <Flex
          alignItems="center"
          justifyContent="center"
          height="100%"
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          style={{ zIndex: 2 }}
        >
          <PinPadLayover>{overlayResult}</PinPadLayover>
        </Flex>
      )}
    </>
  );
};

import { useEffect, useState } from 'react';

import { useAppDispatch } from '../App/useRedux';
import { updateRegistrationFlow } from '../../store/AppSettings/AppSettings.redux';

// Models
import { Configuration } from '../../models/Configurations';

// Utils
import { RegistrationFlow, Step, StepConfiguration } from '../../models/enums/Step';
import { KioskMediaType } from '../../models/enums/KioskMediaType';

// Hook
export const useStepBuilder = (configuration: Configuration | null) => {
  const dispatch = useAppDispatch();

  // State
  const [selfRegistrationSteps, setSelfRegistrationSteps] = useState<StepConfiguration[]>([]);
  const [preRegistrationSteps, setPreRegistrationSteps] = useState<StepConfiguration[]>([]);

  // Effects
  // This should be set in the backend
  useEffect(() => {
    if (configuration) {
      dispatch(updateRegistrationFlow(RegistrationFlow.SelfRegistration));

      const selfRegistrationStepsBuilder: StepConfiguration[] = [];
      const preRegistrationStepsBuilder: StepConfiguration[] = [];
      let currentIndex = 0;

      const hasWelcome = true;
      const hasQrOrPin = true;
      const hasRegister = true;
      const hasSecurityQuestions =
        configuration.EnableSecurityRegulations &&
        configuration.Media.some((x) => x.MediaType === KioskMediaType.SecurityRegulation);
      const hasGuestInstructions =
        configuration.EnableGuestInstructions &&
        configuration.Media.some((x) => x.MediaType === KioskMediaType.GuestInstructions);
      const hasNda = configuration.EnableNda && configuration.Media.some((x) => x.MediaType === KioskMediaType.Nda);
      const hasFinish = true;

      if (hasWelcome) {
        selfRegistrationStepsBuilder.push({
          Index: currentIndex,
          StepName: Step.Welcome,
        });
        preRegistrationStepsBuilder.push({
          Index: currentIndex,
          StepName: Step.Welcome,
        });
        currentIndex += 1;
      }

      if (hasQrOrPin) {
        selfRegistrationStepsBuilder.push({
          Index: currentIndex,
          StepName: Step.QrOrPin,
        });
        preRegistrationStepsBuilder.push({
          Index: currentIndex,
          StepName: Step.QrOrPin,
        });
        currentIndex += 1;
      }

      if (hasNda) {
        selfRegistrationStepsBuilder.push({
          Index: currentIndex,
          StepName: Step.Nda,
        });
        preRegistrationStepsBuilder.push({
          Index: currentIndex,
          StepName: Step.Nda,
        });
        currentIndex += 1;
      }

      if (hasSecurityQuestions) {
        selfRegistrationStepsBuilder.push({
          Index: currentIndex,
          StepName: Step.SecurityRegulations,
        });
        preRegistrationStepsBuilder.push({
          Index: currentIndex,
          StepName: Step.SecurityRegulations,
        });
        currentIndex += 1;
      }

      if (hasRegister) {
        selfRegistrationStepsBuilder.push({
          Index: currentIndex,
          StepName: Step.Register,
        });
        currentIndex += 1;
      }

      if (hasGuestInstructions) {
        selfRegistrationStepsBuilder.push({
          Index: currentIndex,
          StepName: Step.GuestInstructions,
        });
        preRegistrationStepsBuilder.push({
          Index: currentIndex,
          StepName: Step.GuestInstructions,
        });
        currentIndex += 1;
      }

      configuration.Actions.forEach(() => {
        selfRegistrationStepsBuilder.push({
          Index: currentIndex,
          StepName: Step.Card,
        });
        preRegistrationStepsBuilder.push({
          Index: currentIndex,
          StepName: Step.Card,
        });
        currentIndex += 1;
      });

      if (hasFinish) {
        selfRegistrationStepsBuilder.push({
          Index: currentIndex,
          StepName: Step.Finish,
        });
        preRegistrationStepsBuilder.push({
          Index: currentIndex,
          StepName: Step.Finish,
        });
      }

      setSelfRegistrationSteps(selfRegistrationStepsBuilder);
      setPreRegistrationSteps(preRegistrationStepsBuilder);
    }
  }, [configuration, dispatch]);

  // Hook Data
  return {
    selfRegistrationSteps,
    preRegistrationSteps,
  };
};

import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { Drawer, Layout, Typography } from 'antd';
import styled from 'styled-components';

// Redux
import { useAppDispatch, useAppSelector } from '../../../hooks/App/useRedux';
import { openSidebar, updateWindowWidth } from '../../../store/AppSettings/AppSettings.redux';

// Components
import { CustomScrollbars } from '../../UI/Core/CustomScrollbars';
import { Translated } from '../../UI/Core/Translated';

// Subcomponents
const { Sider } = Layout;
const { Title } = Typography;

// Styled Title
const StyledTitle = styled(Title)`
  padding: 20px 20px 0px 20px;
`;

// Component
export const MobileMenu = () => {
  // Redux
  const dispatch = useAppDispatch();
  const { sidebarOpen, width } = useAppSelector(({ appSettings }) => appSettings);

  // Debounced* | Updates app width and closes the drawer accordingly
  const [currentWidth, setCurrentWidth] = useState(width);
  useDebounce(() => dispatch(updateWindowWidth(currentWidth)), 200, [currentWidth]);
  useEffect(() => window.addEventListener('resize', () => setCurrentWidth(window.innerWidth)), [dispatch]);

  // UI Handlers
  const onToggleCollapsedNav = () => {
    dispatch(openSidebar(!sidebarOpen));
  };

  return (
    <Sider className="gx-app-sidebar gx-collapsed-sidebar">
      <Drawer
        className="gx-drawer-sidebar"
        placement="left"
        closable={false}
        onClose={onToggleCollapsedNav}
        open={sidebarOpen}
      >
        <div className="gx-sidebar-content">
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <StyledTitle level={5}>
              <Translated id="app.name" />
            </StyledTitle>
          </CustomScrollbars>
        </div>
      </Drawer>
    </Sider>
  );
};

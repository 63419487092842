import React from 'react';
import { Routes } from 'react-router';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';

// Helpers
import { routes } from '../../routes/Routes';

// Components
import { AppLayout } from './AppLayout/AppLayout';
import { ErrorContainer } from '../UI/Core/ErrorContainer';
import { useAppSettings } from '../../hooks/App/useAppSettings';
import { Timeout } from './Timeout/Timeout';

export const App = () => {
  const { currentAppLocale } = useAppSettings();
  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd}>
        <AppLayout>
          <ErrorContainer>
            <Timeout />
            <Routes>{routes}</Routes>
          </ErrorContainer>
        </AppLayout>
      </ConfigProvider>
    </IntlProvider>
  );
};

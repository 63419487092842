import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useIntl } from 'react-intl';

const key = 'timeout';
export const Timeout = () => {
  const [idleSeconds, setIdleSeconds] = useState(0);
  const intl = useIntl();

  useEffect(() => {
    if (idleSeconds > 60 * 15) {
      window.location.reload();
    } else if (idleSeconds > 60 * 14 + 30) {
      notification.warning({
        key,
        message: intl.formatMessage({
          id: 'app.timeout.message',
        }),
        description: intl.formatMessage(
          {
            id: 'app.timeout.description',
          },
          {
            time: 60 * 15 - idleSeconds,
          }
        ),
        duration: 0,
      });
    } else {
      notification.close(key);
    }
  }, [idleSeconds, intl]);

  useEffect(() => {
    const restartAutoReset = () => {
      setIdleSeconds(0);
    };

    const timeout = setInterval(() => setIdleSeconds((oldTime) => oldTime + 1), 1000);

    // listen for mouse events
    window.addEventListener('mousemove', restartAutoReset);
    window.addEventListener('touchstart', restartAutoReset);

    // cleanup
    return () => {
      if (timeout) {
        clearTimeout(timeout);
        window.removeEventListener('mousemove', restartAutoReset);
        window.removeEventListener('touchstart', restartAutoReset);
      }
    };
  }, []);

  return <div />;
};

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Result } from 'antd';
import QrScanner from 'qr-scanner';
import { useDebounce, usePrevious, useThrottle } from 'react-use';

const doNothing = () => {
  // Because typescript requires the same type being returned every time,
  // this function can be used in guard clauses of useEffect when there is no cleanup.
};

// Props
interface OnQrCodeChangedProps {
  qrCode: string;
}

interface QrScannerProps {
  onQrChanged: ({ qrCode }: OnQrCodeChangedProps) => void;
}

// Hook
export const useQrCodeScanner = ({ onQrChanged }: QrScannerProps) => {
  const videoRef = useRef(null);
  const [hasCamera, setHasCamera] = useState(false);
  const [scanResult, setScanResult] = useState('');

  // Fetch data using throttle
  const qrCode = useThrottle(scanResult, 1000);
  const oldQrCode = usePrevious(qrCode);

  useEffect(() => {
    if (qrCode && qrCode !== oldQrCode) onQrChanged({ qrCode });
  }, [qrCode, oldQrCode, onQrChanged]);

  // Fetch data with debounce
  useDebounce(
    () => {
      if (qrCode) setScanResult('');
    },
    5000,
    [qrCode]
  );

  // When a camera is found and a videoRef is created we start the QR scanner.
  useEffect(() => {
    if (videoRef.current === null || !hasCamera) {
      return doNothing;
    }
    const scanner = new QrScanner(videoRef.current, (result) => setScanResult(result.data), {
      maxScansPerSecond: 5,
    });
    scanner.start().catch(() => {
      setHasCamera(false);
    });
    return () => {
      scanner.stop();
    };
  }, [videoRef, hasCamera]);

  // Find out if there is a camera on init.
  useEffect(() => {
    QrScanner.hasCamera().then((result) => {
      setHasCamera(result);
    });
  }, []);

  // Camera Feed
  const QrCamera = useMemo(
    () =>
      hasCamera ? (
        <video muted playsInline id="qr-video" width="100%" height="100%" ref={videoRef} />
      ) : (
        <Result status="warning" title="No camera found!" />
      ),
    [hasCamera, videoRef]
  );

  return useMemo(() => ({ QrCamera }), [QrCamera]);
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { AccountFormValues } from '../../models/AccountFormValues';
import { SignalRAction } from '../../models/SignalRAction';

// State
interface AccountState {
  updating: boolean;
  error: boolean;
}

const initialState: AccountState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@ACCOUNT';

// Redux Actions|Reducers
const accountSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Change
    changeAccount: (state, action: PayloadAction<{ formValues: AccountFormValues }>) => {
      state.updating = true;
      state.error = false;
    },
    accountChanged: (state) => {
      state.updating = false;
      state.error = false;
    },
    accountNotChanged: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Assign
  changeAccount,
  accountChanged,
  accountNotChanged,
} = accountSlice.actions;

// Export Reducer
export default accountSlice.reducer;

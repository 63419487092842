import React from 'react';
import styled from 'styled-components';

// Components
import { useQrCodeScanner } from '../../hooks/QrCodeScanner/useQrCodeScanner';
import { useData } from '../../hooks/App/useData';
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { Flex } from '../UI/Base';
import { Overlay } from '../../models/enums/Overlay';
import { Step } from '../../models/enums/Step';
import { useOverlay } from '../../hooks/Kiosk/useOverlay';

// Styled
const ScannerLayover = styled.div`
  height: 70%;
  width: 70%;
  border-radius: 12px;

  background: linear-gradient(to right, white 6px, transparent 6px) 0 0,
    linear-gradient(to right, white 6px, transparent 6px) 0 100%,
    linear-gradient(to left, white 6px, transparent 6px) 100% 0,
    linear-gradient(to left, white 6px, transparent 6px) 100% 100%,
    linear-gradient(to bottom, white 6px, transparent 6px) 0 0,
    linear-gradient(to bottom, white 6px, transparent 6px) 100% 0,
    linear-gradient(to top, white 6px, transparent 6px) 0 100%,
    linear-gradient(to top, white 6px, transparent 6px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 40px 40px;
  outline: 21rem solid rgb(0 0 0 / 0.5);
`;

interface QrCodeScannerProps {
  onNext: () => void;
  onGoTo: (step: Step) => void;
}

export const QrCodeScanner = ({ onNext, onGoTo }: QrCodeScannerProps) => {
  // Data
  const { data: guest, fetch, pending } = useData(ApiEndpoints.guests.getByQrCode, null);
  const { status, setStatus, overlayResult } = useOverlay({ onNext, onGoTo, guest, pending });

  // Hooks
  const { QrCamera } = useQrCodeScanner({
    onQrChanged: ({ qrCode }) => {
      fetch({ qrCode });
      setStatus(Overlay.Loading);
    },
  });

  return (
    <>
      {QrCamera}
      {status !== Overlay.None && (
        <Flex alignItems="center" justifyContent="center" height="100%" top="-120%" position="relative">
          <ScannerLayover>{overlayResult}</ScannerLayover>
        </Flex>
      )}
    </>
  );
};

/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import styled from 'styled-components';

// Components
import { Widget, WidgetCardProps } from './Widget';

// Props
interface KioskWidgetProps extends WidgetCardProps {
  coverSize?: number;
}

// Styled
export const KioskWidget = styled(({ coverSize: _coverSize, children, ...rest }: KioskWidgetProps) => (
  <Widget {...rest}>{children}</Widget>
))`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  margin-top: 20px;
  min-height: 320px;

  & .ant-card-head {
    background: #fafafa;
  }

  & .ant-card-cover {
    overflow: hidden;
    height: ${(props) => props.coverSize || 45}%;

    & video {
      object-fit: cover;
      height: 100%;
    }
  }

  & .ant-card-body {
    padding: 16px 24px;
    overflow: auto;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  & .ant-card-actions {
    padding: 8px 24px;
    border-radius: 0 0 10px 10px;
    background-color: #fff;

    & .ant-btn {
      margin-bottom: 0px;
    }
  }
`;

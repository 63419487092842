// Types
import { SignalREvent } from '../models/SignalREvent';

// Generic
import { configureSignalREvents as configureGenericActions } from './Generic/Generic.signalr';
// Account
import { configureSignalREvents as configureAccountsActions } from './Account/Account.signalr';
// Visits
import { configureSignalREvents as configureVisitsActions } from './Visits/Visits.signalr';

// Endpoint Configuration
export const configureReduxEvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Generic
  configureGenericActions({ hubConnection, store, history });
  // Account
  configureAccountsActions({ hubConnection, store, history });
  // Visits
  configureVisitsActions({ hubConnection, store, history });
};

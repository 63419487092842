import React from 'react';
import { v4 as uuid } from 'uuid';
import { Route } from 'react-router';

// Routes
import { getAuthorizationRoutes } from './AuthorizationRoutes';

// Components
import { AuthorizedRoute } from '../components/UI/ApiAuthorization/AuthorizedRoute';

// Pages
import { NotFoundPage } from '../pages/App/NotFoundPage';
import { HomePage } from '../pages/Kiosk/HomePage';
import { InvitationPage } from '../pages/Invitation/InvitationPage';
import { InvitationAcceptedPage } from '../pages/Invitation/InvitationAcceptedPage';
import { InvitationDeclinedPage } from '../pages/Invitation/InvitationDeclinedPage';
import { InvitationExpiredPage } from '../pages/Invitation/InvitationExpiredPage';

export const routes = [
  <Route key={uuid()} path="/" element={<AuthorizedRoute path="/" />}>
    {/* Kiosk */}
    <Route path="/" key={uuid()} element={<HomePage />} />
    {/* PersonalizedKiosk */}
    <Route path="/:id" key={uuid()} element={<HomePage />} />
    {/* default */}
    <Route path="*" key={uuid()} element={<NotFoundPage />} />
  </Route>,
  /* Invitation */
  <Route path="/invitation/:id" key={uuid()} element={<InvitationPage />} />,
  <Route path="/invitation/accepted" key={uuid()} element={<InvitationAcceptedPage />} />,
  <Route path="/invitation/declined" key={uuid()} element={<InvitationDeclinedPage />} />,
  <Route path="/invitation/expired" key={uuid()} element={<InvitationExpiredPage />} />,

  getAuthorizationRoutes(),
];

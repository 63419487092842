import React, { ReactNode } from 'react';
import { Result, Skeleton, StepProps, Typography } from 'antd';

// Assets
import BlueKiosk from '../../assets/videos/BlueKiosk.mp4';
import Checkmark from '../../assets/images/animations/checkmark.gif';

// Components
import { Flex } from '../UI/Base';
import { ColorButton } from '../UI/Button/ColorButton';
import { Translated } from '../UI/Core';
import { Locale } from '../../models/Locale';
import { Media, Text } from '../../models/Configurations';
import { HTMLSanitize } from './HTMLSanitize';
import { Step } from '../../models/enums/Step';

const { Title, Paragraph } = Typography;

// Types
type StepActions = {
  // eslint-disable-next-line react/no-unused-prop-types
  onNext: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  onPrev: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  onGoTo: (step: Step) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  onSwitchLocale: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  onShowPinPad: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  targetLocale: Locale;
  // eslint-disable-next-line react/no-unused-prop-types
  onReject: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  isConfigured: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  pending: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  onFetchConfiguration: () => void;
};

export type KioskStep = {
  content: ReactNode;
  cover?: ReactNode;
  coverSize?: number;
  actions?: ReactNode[];
  index?: number;
  step: Step;
} & StepProps;

interface TextProps {
  text: Text;
  pending: boolean;
}

interface MediaProps {
  media?: Media;
  pending: boolean;
}

// Components
export const IntroMedia = ({ media, pending }: MediaProps) => {
  if (pending) return <Skeleton paragraph={{ rows: 8 }} style={{ padding: '24px', marginTop: '2%' }} />;

  return (
    <>
      {!media?.Url ? (
        <video key={BlueKiosk} muted autoPlay loop playsInline>
          <source src={BlueKiosk} type="video/mp4" />
        </video>
      ) : media.MimeType?.startsWith('image') ? (
        <div
          style={{
            height: '100%',
            backgroundImage: `url(${media.Url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      ) : media.MimeType?.startsWith('video') ? (
        <video key={media?.Url} muted autoPlay loop playsInline>
          <source src={media.Url} type="video/mp4" />
        </video>
      ) : (
        <embed style={{ height: '100%', width: '100%' }} src={media.Url} type="application/pdf" />
      )}

      <div
        style={{
          position: 'relative',
          height: '42%',
          top: 'calc(-1*42%)',
          backgroundImage: 'linear-gradient(transparent, white)',
        }}
      />
    </>
  );
};

export const FinishContent = ({ text, pending }: TextProps) => {
  if (pending) return <Skeleton paragraph={{ rows: 7 }} />;

  return (
    <Result
      status="success"
      icon={<img src={Checkmark} alt="checkmark" />}
      title={
        text.CompletionTitle ? (
          <HTMLSanitize html={text.CompletionTitle} styleClass="ql-editor" />
        ) : (
          <Translated id="kiosk.finish.title" />
        )
      }
      subTitle={
        text.CompletionSubtitle ? (
          <HTMLSanitize html={text.CompletionSubtitle} styleClass="ql-editor" />
        ) : (
          <Translated id="kiosk.finish.description" />
        )
      }
    />
  );
};

export const BasicActions = ({
  onNext,
  onSwitchLocale,
  targetLocale,
  onFetchConfiguration,
  isConfigured,
  pending,
}: StepActions) => (
  <Flex key="container">
    {isConfigured ? (
      <ColorButton disabled={pending} color="main" size="large" block xl onClick={() => onSwitchLocale()}>
        {targetLocale.name}
      </ColorButton>
    ) : (
      <ColorButton color="red" size="large" block xl onClick={() => onFetchConfiguration()}>
        <Translated id="kiosk.tryAgain" />
      </ColorButton>
    )}
    <ColorButton
      disabled={!isConfigured || pending}
      color="lime"
      size="large"
      block
      xl
      type="primary"
      onClick={() => onNext()}
    >
      <Translated id="kiosk.continue" />
    </ColorButton>
  </Flex>
);

export const QrActions = ({ onPrev, onShowPinPad, onNext }: StepActions) => (
  <Flex key="container">
    <ColorButton color="orange" size="large" block xl onClick={() => onPrev()}>
      <Translated id="kiosk.back" />
    </ColorButton>
    <ColorButton color="main" size="large" block xl onClick={() => onShowPinPad()}>
      <Translated id="kiosk.usePin" />
    </ColorButton>
    <ColorButton color="green" size="large" block xl onClick={() => onNext()}>
      <Translated id="kiosk.register" />
    </ColorButton>
  </Flex>
);

export const PinActions = ({ onPrev }: StepActions) => (
  <Flex key="container">
    <ColorButton color="orange" size="large" block xl onClick={() => onPrev()}>
      <Translated id="kiosk.back" />
    </ColorButton>
  </Flex>
);

export const GuestInstructionsActions = ({ onPrev, onNext }: StepActions) => (
  <Flex key="container">
    <ColorButton color="orange" size="large" block xl onClick={() => onPrev()}>
      <Translated id="kiosk.back" />
    </ColorButton>
    <ColorButton color="orange" size="large" block xl onClick={() => onNext()}>
      <Translated id="kiosk.continue" />
    </ColorButton>
  </Flex>
);

export const SecurityRegulationsActions = ({ onPrev, onNext, onReject }: StepActions) => (
  <Flex key="container">
    <ColorButton color="orange" size="large" block xl onClick={() => onPrev()}>
      <Translated id="kiosk.back" />
    </ColorButton>
    <ColorButton color="red" size="large" block xl onClick={() => onReject()}>
      <Translated id="app.decline" />
    </ColorButton>
    <ColorButton color="green" size="large" block xl onClick={() => onNext()}>
      <Translated id="app.accept" />
    </ColorButton>
  </Flex>
);

export const NdaActions = ({ onPrev, onNext }: StepActions) => (
  <Flex key="container">
    <ColorButton color="orange" size="large" block xl onClick={() => onPrev()}>
      <Translated id="kiosk.back" />
    </ColorButton>
    <ColorButton color="orange" size="large" block xl onClick={() => onNext()}>
      <Translated id="kiosk.continue" />
    </ColorButton>
  </Flex>
);

export const RegisterActions = ({ onPrev, onNext }: StepActions) => (
  <Flex key="container">
    <ColorButton color="orange" size="large" block xl onClick={() => onPrev()}>
      <Translated id="kiosk.back" />
    </ColorButton>
    <ColorButton color="green" size="large" block xl onClick={() => onNext()}>
      <Translated id="kiosk.save" />
    </ColorButton>
  </Flex>
);

export const FinishActions = ({ onGoTo }: StepActions) => (
  <Flex key="container">
    <ColorButton color="gold" size="large" block xl onClick={() => onGoTo(Step.Welcome)}>
      <Translated id="kiosk.backToStart" />
    </ColorButton>
  </Flex>
);

export const IntroContent = ({ text, pending }: TextProps) => {
  if (pending) return <Skeleton paragraph={{ rows: 7 }} style={{ marginTop: '2%' }} />;

  return (
    <Flex direction="column" alignItems="center">
      {text.IntroTitle ? (
        <div style={{ padding: '2px' }}>
          <HTMLSanitize html={text.IntroTitle ?? ''} styleClass="ql-editor" />
        </div>
      ) : (
        <Title style={{ marginBottom: 0 }}>
          <>
            <Translated id="kiosk.intro.welcomeTo" />{' '}
            <span style={{ color: '#12a3c8' }}>
              <Translated id="app.name" />
            </span>
          </>
        </Title>
      )}

      {text.IntroSubtitle ? (
        <div style={{ marginTop: 0, padding: '1px' }}>
          <HTMLSanitize html={text.IntroSubtitle ?? ''} styleClass="ql-editor" />
        </div>
      ) : (
        <Title level={4} style={{ marginTop: 0 }}>
          {text.IntroSubtitle || <Translated id="kiosk.intro.subtext" />}
        </Title>
      )}

      {text.IntroSubtext ? (
        <div style={{ padding: '8px' }}>
          <HTMLSanitize html={text.IntroSubtext ?? ''} styleClass="ql-editor" />
        </div>
      ) : (
        <Paragraph style={{ fontSize: '1.2em' }}>{text.IntroSubtext || <Translated id="kiosk.intro.text" />}</Paragraph>
      )}
    </Flex>
  );
};

export const QrContent = (
  <Flex direction="column" alignItems="center">
    <Title level={2}>
      <Translated id="kiosk.qr.received" />{' '}
      <span style={{ color: '#12a3c8' }}>
        <Translated id="kiosk.qr" />
      </span>
      ?
    </Title>
    <Paragraph style={{ fontSize: '1.2em' }}>
      <Translated id="kiosk.qr.description" />
    </Paragraph>
  </Flex>
);

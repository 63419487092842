import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { Visit } from '../../models/Visits/Visit';
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import {
  createVisit,
  executeAction,
  setTraceId,
  updateVisitStatus,
  visitCreated,
  visitNotCreated,
  visitStatusNotUpdated,
} from './Visits.redux';
import { Action } from '../../models/Visits/Action';

// **************************************************
// ********************* ACTIONS ********************

// Worker Sagas
function* executeActionSage() {
  yield takeEvery(executeAction.type, executeActionRequest);
}

// Request
function* executeActionRequest(action: PayloadAction<Action>) {
  try {
    const { payload: data } = action;

    const traceId = crypto.randomUUID();
    yield apiService.execute({
      url: `Actions/${data.ActionId}/Execute`,
      method: ApiRequestType.PUT,
      data,
      traceId,
    });
    yield put({ type: setTraceId.type, payload: traceId });
  } catch ({ message }) {
    yield put({ type: visitNotCreated.type, payload: { msg: { message } } });
  }
}

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createVisitSaga() {
  yield takeEvery(createVisit.type, createVisitRequest);
}

function* visitCreatedSaga() {
  yield takeEvery(visitCreated.type, createVisitSuccess);
}

function* visitNotCreatedSaga() {
  yield takeLatest(visitNotCreated.type, createVisitError);
}

// Request
function* createVisitRequest(action: PayloadAction<Visit>) {
  try {
    const { payload: data } = action;

    const traceId = crypto.randomUUID();
    yield apiService.execute({
      url: `Visits/Kiosks/${data.KioskId}`,
      method: ApiRequestType.POST,
      data,
      traceId,
    });
    yield put({ type: setTraceId.type, payload: traceId });
  } catch ({ message }) {
    yield put({ type: visitNotCreated.type, payload: { msg: { message } } });
  }
}

function* createVisitSuccess(action: PayloadAction<SignalRAction>) {
  const { payload: data } = action;
  if (data.msg.submitSignature && data.msg.submitSignatureGuest && data.msg.submitSignatureKiosk) {
    try {
      yield apiService.execute({
        url: `Visits/Kiosks/${data.msg.submitSignatureKiosk}/Sign/${data.msg.submitSignatureGuest}`,
        method: ApiRequestType.POST,
        data: data.msg.submitSignature,
      });
    } catch ({ message }) {
      yield put({ type: visitNotCreated.type, payload: { msg: { message } } });
    }
  }
}

// Error
function createVisitError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('visits.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE STATUS **************

// Worker Sagas
function* updateVisitStatusSaga() {
  yield takeEvery(updateVisitStatus.type, updateVisitStatusRequest);
}

function* visitStatusNotUpdatedSaga() {
  yield takeLatest(visitStatusNotUpdated.type, updateVisitStatusError);
}

// Request
function* updateVisitStatusRequest(action: PayloadAction<Visit>) {
  try {
    const { payload: data } = action;
    yield apiService.execute({
      url: `Visits/${data.Id}/Status`,
      method: ApiRequestType.PUT,
      data,
    });
  } catch ({ message }) {
    yield put({ type: visitStatusNotUpdated.type, payload: { msg: { message } } });
  }
}

// Error
function updateVisitStatusError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('visits.notifications.updateStatusFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createVisitSaga(),
    visitCreatedSaga(),
    visitNotCreatedSaga(),
    // Update Status
    updateVisitStatusSaga(),
    visitStatusNotUpdatedSaga(),
    // Actions
    executeActionSage(),
  ]);
}

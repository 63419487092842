import React from 'react';
import { Skeleton, Typography } from 'antd';
import { Translated } from '../UI/Core';
import { Media } from '../../models/Configurations';

const { Title } = Typography;

interface SecurityRegulationsProps {
  media?: Media;
  pending: boolean;
}

export const SecurityRegulations = ({ media, pending }: SecurityRegulationsProps) => {
  if (pending) return <Skeleton paragraph={{ rows: 8 }} style={{ padding: '24px', marginTop: '2%' }} />;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '65vh',
        overflow: 'hidden',
      }}
    >
      <Title level={2}>
        <Translated id="kiosk.securityRegulation" />
      </Title>
      {media?.Url && (
        <div
          style={{
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          {media.MimeType.startsWith('image') ? (
            <img
              src={media.Url}
              style={{
                objectFit: 'cover',
                maxWidth: '100%',
                display: 'block',
                margin: 'auto',
              }}
              alt="security regulations"
            />
          ) : media.MimeType?.startsWith('video') ? (
            <video key={media?.Url} muted autoPlay loop playsInline>
              <source src={media.Url} type="video/mp4" />
            </video>
          ) : (
            <embed style={{ width: '100%', height: '100%' }} src={`${media.Url}#toolbar=0`} type="application/pdf" />
          )}
        </div>
      )}
    </div>
  );
};

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// Hooks
import { useAuth } from '../../../hooks/App/useAuth';

// Constants
import { getRedirectUrl } from '../../../constants/Utils/Urls';

// Components
import { AuthSpinner } from './AuthSpinner';

// Props
interface AuthorizedRouteProps {
  path: string;
}

// Component
export const AuthorizedRoute = ({ path }: AuthorizedRouteProps) => {
  const {
    authState: { isInitialized, isAuthenticated },
  } = useAuth();

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  // eslint-disable-next-line no-nested-ternary
  return isInitialized ? (
    isAuthenticated ? (
      <Outlet />
    ) : (
      <Navigate to={getRedirectUrl(path as string)} />
    )
  ) : (
    <AuthSpinner message="Loading..." />
  );
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */

import './assets/vendors/style.less';
import './styles/bluekiosk.less';
import '@fortawesome/fontawesome-free/css/all.min.css';

import React from 'react';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';

// Services
import { configureReduxStore } from './services/Redux/ReduxStore';

// Components
import { App } from './components/App/App';
import { configureMoment } from './constants/Utils/DateTime';

// Variables
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const history = createBrowserHistory();

// Configure moment
configureMoment();

// Initializes Store
const store = configureReduxStore(history);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Initializes React (v18)
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    {/* @ts-expect-error Temporal Fix for History Props */}
    <HistoryRouter basename={baseUrl} history={history}>
      <App />
    </HistoryRouter>
  </Provider>
);

import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { useIntl } from 'react-intl';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Translated } from '../UI/Core';
import { GuestStatus } from '../../models/enums/GuestStatus';
import { ColorButton } from '../UI/Button/ColorButton';

const { confirm } = Modal;

interface ConfirmDeclineButtonProps {
  onSubmit: (guestStatus: GuestStatus) => Promise<void>;
}

export const ConfirmDeclineButton = ({ onSubmit }: ConfirmDeclineButtonProps) => {
  const intl = useIntl();

  // Confirmation Modals
  const showDeclineConfirm = useCallback(() => {
    confirm({
      title: intl.formatMessage({
        id: 'invitations.modal.title',
      }),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage({
        id: 'invitations.modal.content',
      }),
      okText: intl.formatMessage({
        id: 'app.yes',
        defaultMessage: 'Yes',
      }),
      cancelText: intl.formatMessage({
        id: 'app.no',
        defaultMessage: 'No',
      }),
      okType: 'danger',
      onOk: () => onSubmit(GuestStatus.Declined),
      onCancel: () => null,
      maskClosable: true,
    });
  }, [intl, onSubmit]);

  return (
    <ColorButton color="orange" size="large" block xl onClick={() => showDeclineConfirm()}>
      <Translated id="app.decline" />
    </ColorButton>
  );
};

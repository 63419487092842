import { SagaMiddleware } from 'redux-saga';

// Generic
import GenericSagas from './Generic/Generic.sagas';
// Account
import AccountSagas from './Account/Account.sagas';
// AppSettings
import AppSettingsSagas from './AppSettings/AppSettings.sagas';
// Visits
import VisitsSagas from './Visits/Visits.sagas';
// Invitation
import InvitationSagas from './Invitation/Invitation.sagas';

export const runSagas = (sagaMiddleware: SagaMiddleware<object>) => {
  // Generic
  sagaMiddleware.run(GenericSagas);
  // Account
  sagaMiddleware.run(AccountSagas);
  // AppSettings
  sagaMiddleware.run(AppSettingsSagas);
  // Visits
  sagaMiddleware.run(VisitsSagas);
  // Invitation
  sagaMiddleware.run(InvitationSagas);
};

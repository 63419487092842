import React from 'react';
import { Result } from 'antd';
import styled from 'styled-components';

// Components
import { Translated } from '../../components/UI/Core';
import { Container } from '../../components/UI/Base';
import { Widget } from '../../components/UI/Widget/Widget';
import Warning from '../../assets/images/warning.svg';

// Styled UI component
const StyledWidget = styled(Widget)`
  margin: 16px 0;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;

  & .ant-card-body {
    padding: 12px 16px 0;
  }
`;

export const InvitationDeclinedPage = () => (
  <Container style={{ height: '100%' }}>
    <StyledWidget>
      <Result
        status="success"
        icon={<img src={Warning} style={{ height: 220 }} alt="warning" />}
        title={<Translated id="invitations.declined.title" />}
        subTitle={<Translated id="invitations.declined.description" />}
      />
    </StyledWidget>
  </Container>
);

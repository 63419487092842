import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { InvitationCommand } from '../../models/Visits/Invitation';
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import { fillInvitationDetails, invitationDetailsNotFilled } from './Invitation.redux';

// **************************************************
// ********************* FILL INVITE DETAILS *********************

// Worker Sagas
function* fillInvitationDetailsSaga() {
  yield takeEvery(fillInvitationDetails.type, fillInvitationDetailsRequest);
}

function* invitationDetailsNotFilledSaga() {
  yield takeLatest(invitationDetailsNotFilled.type, fillInvitationDetailsError);
}

// Request
function* fillInvitationDetailsRequest(action: PayloadAction<InvitationCommand>) {
  try {
    const { payload: invitation } = action;

    yield apiService.execute({
      url: `Invitations/${invitation.Invitation.Id}`,
      method: ApiRequestType.PUT,
      data: invitation,
    });
  } catch ({ message }) {
    yield put({ type: invitationDetailsNotFilled.type, payload: { msg: { message } } });
  }
}

// Error
function fillInvitationDetailsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('invitations.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    fillInvitationDetailsSaga(),
    invitationDetailsNotFilledSaga(),
  ]);
}

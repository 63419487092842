export const appLanguages = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'gb',
  },
  {
    languageId: 'dutch',
    locale: 'nl',
    name: 'Nederlands',
    icon: 'nl',
  },
];

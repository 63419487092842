import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Skeleton, Typography } from 'antd';
import SignatureCanvas from 'react-signature-canvas';

import { DeleteOutlined } from '@ant-design/icons';

import { Translated } from '../UI/Core';
import { Media } from '../../models/Configurations';
import { notificationService } from '../../services/Notifications/NotificationService';

const { Title } = Typography;

interface NdaProps {
  media?: Media;
  pending: boolean;
  setCompletionCallback: (callback: () => Promise<boolean>) => void;
  setSignature: (signature: string | undefined, media: Media | undefined) => void;
}

export const Nda = ({ media, pending, setCompletionCallback, setSignature }: NdaProps) => {
  const [ndaChecked, setNdaChecked] = useState(false);
  const [isSigned, setIsSigned] = useState<boolean>(false);
  const sigCanvas = useRef<SignatureCanvas | null>(null);
  const checkIfSigned = useCallback((): boolean => {
    const signatureData = sigCanvas.current?.isEmpty();
    return !signatureData;
  }, [sigCanvas]);

  const onCompletion = useCallback(async () => {
    if (isSigned && ndaChecked) {
      const signImageBytes = sigCanvas.current?.toDataURL();
      setSignature(signImageBytes, media);
      return true;
    }
    notificationService.showError('kiosk.notifications.ndaNotSigned');
    return false;
  }, [isSigned, ndaChecked, setSignature, media]);

  useEffect(() => {
    setCompletionCallback(onCompletion);
  }, [setCompletionCallback, onCompletion]);

  const clearSignature = useCallback(() => {
    sigCanvas.current?.clear();
    setIsSigned(false);
  }, [setIsSigned]);

  if (pending) return <Skeleton paragraph={{ rows: 8 }} style={{ padding: '24px', marginTop: '2%' }} />;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '65vh',
        overflow: 'hidden',
      }}
    >
      <Title level={2}>
        <Translated id="kiosk.nda" />
      </Title>
      {media?.Url && (
        <div
          style={{
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          {media.MimeType.startsWith('image') ? (
            <img
              src={media.Url}
              style={{
                objectFit: 'cover',
                maxWidth: '100%',
                display: 'block',
                margin: 'auto',
              }}
              alt="nda"
            />
          ) : media.MimeType?.startsWith('video') ? (
            <video key={media?.Url} muted autoPlay loop playsInline>
              <source src={media.Url} type="video/mp4" />
            </video>
          ) : (
            <embed style={{ width: '100%', height: '100%' }} src={`${media.Url}#toolbar=0`} type="application/pdf" />
          )}
        </div>
      )}

      {/* Signature Canvas */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'sticky',
            bottom: '0',
          }}
        >
          <SignatureCanvas
            ref={sigCanvas}
            penColor="black"
            canvasProps={{
              width: 500,
              height: 150,
              className: 'sigCanvas',
              style: {
                border: '2px solid #bfbfbf', // Set border color and width
              },
            }}
            backgroundColor="#ffffff"
            onEnd={() => setIsSigned(checkIfSigned())}
          />
          {isSigned && (
            <Button
              onClick={clearSignature}
              style={{
                position: 'absolute',
                top: '5px',
                right: '0px',
                borderRadius: '4px',
                borderColor: 'white',
                backgroundColor: '#fde1e1',
              }}
            >
              <DeleteOutlined style={{ color: 'red', fontSize: '18px' }} onClick={clearSignature} />
            </Button>
          )}
          <Checkbox onChange={(e) => setNdaChecked(e.target.checked)}>
            <Translated id="kiosk.nda.agreeToSign" />
          </Checkbox>
        </div>
      </div>
    </div>
  );
};

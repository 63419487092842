import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox, Col, Divider, Form, Input, Row, Skeleton } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router';

// Components
import { Translated } from '../../components/UI/Core';
import { Container, Flex } from '../../components/UI/Base';
import { Widget } from '../../components/UI/Widget/Widget';
import { ColorButton } from '../../components/UI/Button/ColorButton';
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { fillInvitationDetails } from '../../store/Invitation/Invitation.redux';

// Models
import { Identifier } from '../../models/Visits/Identifier';
import { GuestStatus } from '../../models/enums/GuestStatus';

// Hooks
import { useAppDispatch } from '../../hooks/App/useRedux';
import { useData } from '../../hooks/App/useData';
import { ConfirmDeclineButton } from '../../components/Invitation/ConfirmDeclineButton';

// Styled UI component
const StyledWidget = styled(Widget)`
  margin: 16px 0 0;

  & .ant-card-body {
    padding: 12px 16px 0;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledFormItem = styled(Form.Item)`
  font-weight: 600;
`;

const StyledInput = styled(Input)`
  padding: 12px;
  box-shadow: 0 8px 6px -11px black;
`;

const StyledContainer = styled('div')`
  width: 100%;
  @media screen and (min-width: 992px) {
    padding-left: 12%;
    padding-right: 12%;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  text-align: left;
  font-weight: 600;
`;

export const InvitationPage = () => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { data: invitation, fetch, pending } = useData(ApiEndpoints.invitation.detail, null);
  const navigate = useNavigate();

  useEffect(() => {
    fetch({ id });
  }, [fetch, id]);

  const initialValues = useMemo(
    () => ({
      Id: invitation?.Id,
      Guest: {
        Id: '',
        QrCode: '',
        PinCode: '',
        Status: invitation?.Guest.Status,
        Visit: {
          Id: '',
          Title: invitation?.Guest.Visit.Title,
          Description: invitation?.Guest.Visit.Description,
          ValidFrom: invitation?.Guest.Visit.ValidFrom,
          ValidTo: invitation?.Guest.Visit.ValidTo,
          VisitType: 0,
          Hosts: [],
          Guests: [],
        },
        Visitor: {
          Id: '',
          FullName: invitation?.Guest.Visitor.FullName,
          FirstName: invitation?.Guest.Visitor.FirstName,
          Prefix: invitation?.Guest.Visitor.Prefix,
          LastName: invitation?.Guest.Visitor.LastName,
          Email: invitation?.Guest.Visitor.Email,
          CompanyName: invitation?.Guest.Visitor.CompanyName,
          PhoneNumber: invitation?.Guest.Visitor.PhoneNumber,
          Identifiers: invitation?.Guest.Visitor.Identifiers?.map((x: Identifier) => ({
            Value: x.Value ?? '',
            Name: x.Name ?? '',
            Active: true,
            IdentifierTypeId: x.Id,
            IdentifierType: {
              id: x.IdentifierType.Id,
              name: x.IdentifierType.Name,
              valueLabel: x.IdentifierType.ValueLabel,
              displayLabel: x.IdentifierType.DisplayLabel,
              defaultForCheckIn: x.IdentifierType.DefaultForCheckIn,
              requiredInInvitation: x.IdentifierType.RequiredInInvitation,
            },
          })),
        },
      },
    }),
    [invitation]
  );

  const onSubmit = useCallback(
    async (guestStatus: GuestStatus) => {
      const formValues = guestStatus === GuestStatus.Accepted ? await form.validateFields() : form.getFieldsValue(true);

      dispatch(
        fillInvitationDetails({
          Invitation: {
            Id: initialValues?.Id ?? '',
            FirstName: formValues.Guest.Visitor.FirstName,
            Prefix: formValues.Guest.Visitor.Prefix,
            LastName: formValues.Guest.Visitor.LastName,
            Email: formValues.Guest.Visitor.Email,
            CompanyName: formValues.Guest.Visitor.CompanyName,
            PhoneNumber: formValues.Guest.Visitor.PhoneNumber,
            Status: guestStatus,
            Identifiers: formValues.Guest.Visitor.Identifiers?.filter((x: Identifier) => x.Value).map(
              (x: Identifier) => ({
                Value: x.Value,
                Name: x.Name ?? '',
                Active: true,
                IdentifierTypeId: x.Id,
              })
            ),
          },
        })
      );

      if (guestStatus === GuestStatus.Accepted) {
        navigate('/invitation/accepted');
      } else {
        navigate('/invitation/declined');
      }
    },
    [dispatch, navigate, form, initialValues]
  );

  if (pending) {
    return <Skeleton paragraph={{ rows: 7 }} />;
  }

  if (!invitation) {
    navigate('/invitation/expired');
    return <Skeleton paragraph={{ rows: 7 }} />;
  }

  return (
    <Container style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <StyledWidget>
        <StyledContainer>
          <StyledForm layout="vertical" initialValues={initialValues} form={form}>
            {/* Visit Details */}
            <Col span={24}>
              <h1 style={{ marginBottom: 15, marginTop: 30 }}>
                <Translated id="visits.form.details" />
              </h1>
              <Divider />
            </Col>
            <Row style={{ flexDirection: 'row' }}>
              <Col xs={24} sm={24} md={12}>
                <StyledFormItem
                  name={['Guest', 'Visit', 'Title']}
                  label={
                    <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                      <Translated id="visits.form.title" />
                    </p>
                  }
                >
                  <StyledInput disabled placeholder={intl.formatMessage({ id: 'visits.form.title.placeholder' })} />
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <StyledFormItem
                  name={['Guest', 'Visit', 'Description']}
                  label={
                    <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                      <Translated id="visits.form.description" />
                    </p>
                  }
                >
                  <StyledInput
                    disabled
                    placeholder={intl.formatMessage({ id: 'visits.form.description.placeholder' })}
                  />
                </StyledFormItem>
              </Col>
            </Row>

            {/* Guest Details */}
            <Col span={24}>
              <h1 style={{ marginBottom: 15, marginTop: 10 }}>
                <Translated id="visitors.form.details" />
              </h1>
              <Divider />
            </Col>

            {/* First Name & Prefix */}
            <Row style={{ flexDirection: 'row' }}>
              <Col xs={24} sm={24} md={9}>
                <StyledFormItem
                  name={['Guest', 'Visitor', 'FirstName']}
                  label={
                    <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                      <Translated id="visitors.firstName" />
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'visitors.form.warnings.firstName' }),
                    },
                  ]}
                >
                  <StyledInput placeholder="Hubert" />
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={24} md={6}>
                <StyledFormItem
                  name={['Guest', 'Visitor', 'Prefix']}
                  label={
                    <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                      <Translated id="visitors.prefix" />
                    </p>
                  }
                >
                  <StyledInput placeholder="van der" />
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={24} md={9}>
                <StyledFormItem
                  name={['Guest', 'Visitor', 'LastName']}
                  label={
                    <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                      <Translated id="visitors.lastName" />
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'visitors.form.warnings.lastName' }),
                    },
                  ]}
                >
                  <StyledInput placeholder="Renner" />
                </StyledFormItem>
              </Col>
            </Row>

            {/* Email */}
            <Row style={{ flexDirection: 'row' }}>
              <Col xs={24} sm={24} md={8}>
                <StyledFormItem
                  name={['Guest', 'Visitor', 'Email']}
                  label={
                    <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                      <Translated id="visitors.email" />
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'visitors.form.warnings.email' }),
                    },
                  ]}
                >
                  <StyledInput type="email" placeholder="h.renner@company.com" />
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <StyledFormItem
                  name={['Guest', 'Visitor', 'PhoneNumber']}
                  label={
                    <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                      <Translated id="visitors.phone" />
                    </p>
                  }
                >
                  <StyledInput type="tel" placeholder="+31 6 3208 3380" />
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <StyledFormItem
                  name={['Guest', 'Visitor', 'CompanyName']}
                  label={
                    <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                      <Translated id="visitors.companyName" />
                    </p>
                  }
                >
                  <StyledInput placeholder="Bluefield Smart Access" />
                </StyledFormItem>
              </Col>
            </Row>

            {/* Identifiers */}
            {!!initialValues.Guest.Visitor.Identifiers?.length && (
              <Col span={24}>
                <h1 style={{ marginBottom: 15, marginTop: 10 }}>Identifiers</h1>
                <Divider />
              </Col>
            )}

            {initialValues.Guest.Visitor.Identifiers?.map((data, index) => (
              <Row key={data.IdentifierType.id} style={{ flexDirection: 'row' }}>
                <Col xs={24} sm={24} md={12}>
                  <StyledFormItem
                    name={['Guest', 'Visitor', 'Identifiers', index, 'Value']}
                    label={
                      <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                        {isEmpty(data.IdentifierType.valueLabel) ? 'Value' : data.IdentifierType.valueLabel}
                      </p>
                    }
                    dependencies={[['Guest', 'Visitor', 'Identifiers', index, 'Name']]}
                    rules={[
                      {
                        required: data.IdentifierType.requiredInInvitation,
                        message: intl.formatMessage(
                          { id: 'identifiers.form.warnings.value' },
                          {
                            label: isEmpty(data.IdentifierType.valueLabel)
                              ? 'Value'
                              : data.IdentifierType.valueLabel.toLowerCase(),
                          }
                        ),
                        validator: async (_, value) => {
                          const name = form.getFieldValue(['Guest', 'Visitor', 'Identifiers', index, 'Name']);
                          if (!value && (!!name || data.IdentifierType.requiredInInvitation)) {
                            return Promise.reject(
                              new Error(
                                intl.formatMessage(
                                  { id: 'identifiers.form.warnings.value' },
                                  {
                                    label: isEmpty(data.IdentifierType.valueLabel)
                                      ? 'Value'
                                      : data.IdentifierType.valueLabel.toLowerCase(),
                                  }
                                )
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <StyledInput
                      placeholder={isEmpty(data.IdentifierType.valueLabel) ? 'Value' : data.IdentifierType.valueLabel}
                    />
                  </StyledFormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <StyledFormItem
                    name={['Guest', 'Visitor', 'Identifiers', index, 'Name']}
                    label={
                      <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                        {isEmpty(data.IdentifierType.displayLabel)
                          ? intl.formatMessage({ id: 'visits.form.name' })
                          : data.IdentifierType.displayLabel}
                      </p>
                    }
                    dependencies={[['Guest', 'Visitor', 'Identifiers', index, 'Value']]}
                    rules={[
                      {
                        required: data.IdentifierType.requiredInInvitation,
                        message: intl.formatMessage(
                          { id: 'identifiers.form.warnings.name' },
                          {
                            label: isEmpty(data.IdentifierType.displayLabel)
                              ? intl.formatMessage({ id: 'visits.form.name' })
                              : data.IdentifierType.displayLabel.toLowerCase(),
                          }
                        ),
                        validator: async (_, name) => {
                          const value = form.getFieldValue(['Guest', 'Visitor', 'Identifiers', index, 'Value']);
                          if (!name && (!!value || data.IdentifierType.requiredInInvitation)) {
                            return Promise.reject(
                              new Error(
                                intl.formatMessage(
                                  { id: 'identifiers.form.warnings.name' },
                                  {
                                    label: isEmpty(data.IdentifierType.displayLabel)
                                      ? intl.formatMessage({ id: 'visits.form.name' })
                                      : data.IdentifierType.displayLabel.toLowerCase(),
                                  }
                                )
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <StyledInput
                      placeholder={
                        isEmpty(data.IdentifierType.displayLabel)
                          ? intl.formatMessage({ id: 'visits.form.name' })
                          : data.IdentifierType.displayLabel
                      }
                    />
                  </StyledFormItem>
                </Col>
                <StyledFormItem
                  hidden
                  name={['Guest', 'Visitor', 'Identifiers', index, 'Id']}
                  initialValue={data.IdentifierType.id}
                  label={
                    <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                      {isEmpty(data.IdentifierType.displayLabel)
                        ? intl.formatMessage({ id: 'visits.form.id' })
                        : data.IdentifierType.displayLabel}
                    </p>
                  }
                />
              </Row>
            ))}

            {/* Agree to process */}
            <Row style={{ flexDirection: 'row', marginTop: 20 }}>
              <Col span={24}>
                <StyledFormItem
                  name="agreeToProcess"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: async (_, checked) => {
                        if (!checked) {
                          throw new Error(intl.formatMessage({ id: 'visits.form.warnings.agreeToProcess' }));
                        }
                        return checked;
                      },
                    },
                  ]}
                >
                  <StyledCheckbox>
                    <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                      <Translated id="visitors.agreeToProcessLabel" />
                    </p>
                  </StyledCheckbox>
                </StyledFormItem>
              </Col>
            </Row>
          </StyledForm>
        </StyledContainer>
        <Divider />
        <Flex key="container">
          <ConfirmDeclineButton onSubmit={onSubmit} />
          <ColorButton color="green" size="large" block xl onClick={() => onSubmit(GuestStatus.Accepted)}>
            <Translated id="app.accept" />
          </ColorButton>
        </Flex>
      </StyledWidget>
    </Container>
  );
};

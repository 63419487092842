import { AxiosResponse } from 'axios';

import { TenantData } from '../models/TenantData';
import { ApiResponse } from '../models/ApiResponse';
import { apiService } from '../services/ApiService/ApiService';
import { Guest } from '../models/Visits/Guest';
import { IdentifierType } from '../models/Visits/IdentifierType';
import { Configuration } from '../models/Configurations';
import { Invitation } from '../models/Visits/Invitation';

const autoResolving =
  <A extends any[], T>(fn: (...a: A) => Promise<AxiosResponse<ApiResponse<T>>>) =>
  async (...args: A): Promise<ApiResponse<T>> =>
    (await fn(...args)).data;

export const ApiEndpoints = {
  // Configurations
  kiosk: {
    getConfiguration: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<Configuration>>({
        url: `KioskConfigurations/${id}`,
      })
    ),
  },
  // Tenant
  tenant: {
    data: autoResolving(() =>
      apiService.execute<ApiResponse<TenantData>>({
        url: 'Tenants/Data',
      })
    ),
  },
  // Identifier types
  identifierTypes: {
    get: autoResolving(({ id }) =>
      apiService.execute<ApiResponse<Array<IdentifierType>>>({
        url: `IdentifierTypes/Kiosk/${id}`,
      })
    ),
  },
  // Guests
  guests: {
    getByQrCode: autoResolving(({ qrCode }) =>
      apiService.execute<ApiResponse<Guest>>({
        url: `Guests/GetByQrCode/${qrCode}`,
      })
    ),
    getByPinCode: autoResolving(({ pin, startDate, endDate }) =>
      apiService.execute<ApiResponse<Guest>>({
        url: `Guests/GetByPinCode/${pin}/${startDate}/${endDate}`,
      })
    ),
  },
  // Invitation
  invitation: {
    detail: autoResolving(({ id }) =>
      apiService.executeUnauthorized<ApiResponse<Invitation>>({
        url: `Invitations/${id}`,
      })
    ),
  },
};

import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { useDebounce } from 'react-use';
import { ResultStatusType } from 'antd/lib/result';
import { Result } from 'antd';
import { useAppDispatch } from '../App/useRedux';
import { updateRegistrationFlow } from '../../store/AppSettings/AppSettings.redux';

// Models
// Utils
import { RegistrationFlow, Step } from '../../models/enums/Step';
import { Overlay } from '../../models/enums/Overlay';
import { GuestStatus } from '../../models/enums/GuestStatus';
import { setGuest, updateVisitStatus } from '../../store/Visits/Visits.redux';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { Translated } from '../../components/UI/Core';
import { Guest } from '../../models/Visits/Guest';
import { BaseColors } from '../../constants/Utils/Colors';

interface useOverlayProps {
  setPin?: React.Dispatch<React.SetStateAction<string>> | null;
  onNext: () => void;
  onGoTo: (index: number) => void;
  guest: Guest | null;
  pending: boolean;
}

// Hook
export const useOverlay = ({ setPin, onNext, onGoTo, guest, pending }: useOverlayProps) => {
  const dispatch = useAppDispatch();

  // State
  const [status, setStatus] = useState(Overlay.None);

  // When an overlay is displayed we should revert it back after 5 seconds.
  useDebounce(
    () => {
      if (status === Overlay.CheckedIn) {
        onNext();
      }
      if (status === Overlay.CheckedOut) {
        onGoTo(Step.Welcome);
      }
      if (status !== Overlay.None) {
        setStatus(Overlay.None);
      }
    },
    3000,
    [status]
  );

  // Effects
  useEffect(() => {
    if (pending) {
      return;
    }

    if (status !== Overlay.Loading) {
      return;
    }

    if (guest === null) {
      setStatus(Overlay.NotFound);

      if (setPin) {
        setPin('');
      }
      return;
    }

    if ([GuestStatus.Declined].includes(guest.Status)) {
      setStatus(Overlay.Declined);

      if (setPin) {
        setPin('');
      }
      return;
    }

    if ([GuestStatus.Accepted, GuestStatus.Created].includes(guest.Status)) {
      setStatus(Overlay.CheckedIn);
      dispatch(updateRegistrationFlow(RegistrationFlow.PreRegistration));
      dispatch(setGuest(guest));
      dispatch(
        updateVisitStatus({
          Id: guest.Visit.Id,
          GuestId: guest.Visitor.Id,
          GuestStatus: GuestStatus.CheckedIn,
          HostId: null,
          HostStatus: null,
        })
      );
    } else if (guest.Status === GuestStatus.CheckedIn) {
      setStatus(Overlay.CheckedOut);
      dispatch(
        updateVisitStatus({
          Id: guest.Visit.Id,
          GuestId: guest.Visitor.Id,
          GuestStatus: GuestStatus.CheckedOut,
          HostId: null,
          HostStatus: null,
        })
      );
    } else {
      setStatus(Overlay.InvalidVisit);

      if (setPin) {
        setPin('');
      }
    }
  }, [guest, status, pending, setStatus, dispatch, setPin]);

  const overlayResult = useMemo(() => {
    let title: ReactNode = '';
    let type: ResultStatusType;
    switch (status) {
      case Overlay.Loading:
        return <Result icon={<Spinner />} title="" />;
      case Overlay.CheckedIn:
        type = 'success';
        title = <Translated id="kiosk.status.checkedIn" />;
        break;
      case Overlay.CheckedOut:
        type = 'info';
        title = <Translated id="kiosk.status.checkedOut" />;
        break;
      case Overlay.NotFound:
        type = 'error';
        title = <Translated id="kiosk.status.visitorNotFound" />;
        break;
      case Overlay.InvalidVisit:
        type = 'warning';
        title = <Translated id="kiosk.status.invalidVisit" />;
        break;
      case Overlay.Declined:
        type = 'error';
        title = <Translated id="kiosk.securityRegulations.declined" />;
        break;
      default:
        type = 'info';
        break;
    }

    return (
      <Result
        style={{
          backgroundColor: BaseColors.white,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        status={type}
        title={title}
      />
    );
  }, [status]);

  // Hook Data
  return {
    status,
    setStatus,
    overlayResult,
  };
};

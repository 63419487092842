export enum Step {
  Welcome = 0,
  QrOrPin = 1,
  Register = 2,
  SecurityRegulations = 3,
  GuestInstructions = 4,
  Card = 5,
  Finish = 6,
  Nda = 7,
}

export interface StepConfiguration {
  Index: number;
  StepName: Step;
}

export enum RegistrationFlow {
  SelfRegistration = 0,
  PreRegistration = 1,
}

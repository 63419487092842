import React from 'react';
import { Layout } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

// Assets
import iconLogoBf from '../../../assets/images/logo_bluefield_icon.png';
import { Flex } from '../../UI/Base';

// Subcomponents
const { Footer } = Layout;

// Styled
const StyledLogoImg = styled.img`
  height: 18px;
  margin-right: 4px;
`;
const StyledFooterText = styled.span`
  font-size: 0.8rem;
  line-height: 0.7rem;
`;

export const AppFooter = () => (
  <Footer className="gx-container-wrap">
    <Flex justifyContent="center" className="gx-layout-footer-content">
      <Flex alignItems="center">
        <StyledLogoImg alt="" src={iconLogoBf} />
        <StyledFooterText>Bluefield Smart Access © {moment().year()}</StyledFooterText>
      </Flex>
    </Flex>
  </Footer>
);

import { all, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services

// Models
import { Locale } from '../../models/Locale';

// Utils
import { LocalStorageKeys, saveToLocalStorage } from '../../constants/Utils/LocalStorage';

// Redux
import { switchLanguage } from './AppSettings.redux';

// **************************************************
// ********************* LANGUAGE *******************

// Worker Sagas
function* switchLanguageSaga() {
  yield takeEvery(switchLanguage.type, switchLanguageRequest);
}

// Request
function switchLanguageRequest(action: PayloadAction<Locale>) {
  const { payload: locale } = action;
  saveToLocalStorage(LocalStorageKeys.AppSettings.locale, locale);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([switchLanguageSaga()]);
}

/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import styled from 'styled-components';
import { Steps } from 'antd';

// Components
import { Container } from '../../components/UI/Base';
import { KioskWidget } from '../../components/UI/Widget/KioskWidget';
import { Widget } from '../../components/UI/Widget/Widget';
import { useKioskSteps } from '../../hooks/Kiosk/useKioskSteps';

// Styled
const StepsWidget = styled(Widget)`
  margin: 16px 0px 0px;

  & .ant-card-body {
    padding: 12px 16px 0px;

    & .ant-steps-item-content {
      display: none;
    }

    @media screen and (min-width: 992px) {
      & .ant-steps-item-content {
        display: inline-block;
      }
    }
  }
`;

export const HomePage = () => {
  // Hook
  const {
    step,
    steps,
    stepActions: { onGoTo },
  } = useKioskSteps();

  // Render
  return (
    <Container style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <StepsWidget>
        <Steps
          onChange={onGoTo}
          className="gx-pt-0"
          type="navigation"
          current={step?.index}
          responsive={false}
          items={steps}
        />
      </StepsWidget>
      <KioskWidget cover={step?.cover} coverSize={step?.coverSize} actions={step?.actions}>
        {step?.content}
      </KioskWidget>
    </Container>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { InvitationCommand } from '../../models/Visits/Invitation';
import { SignalRAction } from '../../models/SignalRAction';

// State
interface InvitationsState {
  lastEventId?: string;
  updating: boolean;
  error: boolean;
}

const initialState: InvitationsState = {
  lastEventId: undefined,
  updating: false,
  error: false,
};

// Name
const storeName = '@INVITATIONS';

// Redux Actions|Reducers
const invitationsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    fillInvitationDetails: (state, _action: PayloadAction<InvitationCommand>) => {
      state.updating = true;
      state.error = false;
    },
    invitationDetailsNotFilled: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  fillInvitationDetails,
  invitationDetailsNotFilled,
} = invitationsSlice.actions;

// Export Reducer
export default invitationsSlice.reducer;

// Generic
import GenericReducer from './Generic/Generic.redux';
// Account
import AccountReducer from './Account/Account.redux';
// AppSettings
import AppSettingsReducer from './AppSettings/AppSettings.redux';
// Visits
import VisitsReducer from './Visits/Visits.redux';
// Invitation
import InvitationReducer from './Invitation/Invitation.redux';

export const reducers = {
  // Generic
  generic: GenericReducer,
  // Account
  account: AccountReducer,
  // AppSettings
  appSettings: AppSettingsReducer,
  // Visits
  visits: VisitsReducer,
  // Invitation
  invitation: InvitationReducer,
};

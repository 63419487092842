/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { Visit } from '../../models/Visits/Visit';
import { SignalRAction } from '../../models/SignalRAction';
import { VisitStatus } from '../../models/Visits/VisitStatus';
import { Guest } from '../../models/Visits/Guest';
import { Action } from '../../models/Visits/Action';

// State
interface Signature {
  mediaType: number;
  lang: string;
  signatureBase64: string;
}
interface VisitsState {
  lastEventId?: string;
  updating: boolean;
  error: boolean;
  guest?: Guest;
  lastKioskId?: string;
  traceId?: string;
  visitorIsInPacs: boolean;
  lastEmail?: string;
  signature?: Signature;
  lastVisitorId?: string;
  actionExecuting: boolean;
  actionError: boolean;
}

const initialState: VisitsState = {
  lastEventId: undefined,
  updating: false,
  error: false,
  guest: undefined,
  lastKioskId: undefined,
  traceId: undefined,
  visitorIsInPacs: false,
  lastEmail: undefined,
  actionExecuting: false,
  actionError: false,
};

// Name
const storeName = '@VISITS';

// Redux Actions|Reducers
const visitsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    prepareSignature: (state, _action: PayloadAction<Signature | undefined>) => {
      state.signature = _action.payload;
    },
    createVisit: (state, _action: PayloadAction<Visit>) => {
      state.updating = true;
      state.error = false;
      state.lastKioskId = _action.payload.KioskId;
      state.lastEmail = _action.payload.Guests[0].Email;
      state.lastVisitorId = undefined;
      state.actionExecuting = false;
      state.actionError = false;
      state.visitorIsInPacs = false;
    },
    setTraceId(state, _action: PayloadAction<string | undefined>) {
      state.traceId = _action.payload;
    },
    visitCreated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
      if (
        state.traceId !== undefined &&
        _action.payload.msg.traceId === state.traceId &&
        state.lastEmail === _action.payload.msg.guests[0]?.email &&
        _action.payload.msg.guests[0]?.id !== undefined
      ) {
        state.lastVisitorId = _action.payload.msg.guests[0]?.id;
        if (state.signature !== undefined) {
          // Redux and saga magic because this is almost undoable any other way.
          _action.payload.msg.submitSignature = {
            signatureBase64: state.signature.signatureBase64,
            mediaType: state.signature.mediaType,
            lang: state.signature.lang,
          };

          _action.payload.msg.submitSignatureGuest = _action.payload.msg.guests[0]?.id;
          _action.payload.msg.submitSignatureKiosk = state.lastKioskId;
        }
      }
    },
    visitNotCreated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateVisitStatus: (state, _action: PayloadAction<VisitStatus>) => {
      state.updating = true;
      state.error = false;
    },
    visitStatusNotUpdated: (state, _action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    visitUpdatedInPacs: (state, _action: PayloadAction<SignalRAction>) => {
      if (state.traceId !== undefined && _action.payload.msg.traceId === state.traceId) {
        state.visitorIsInPacs = true;
      }
    },

    // Guest
    setGuest: (state, action: PayloadAction<Guest>) => {
      state.guest = action.payload;
    },

    // Actions
    executeAction: (state, _action: PayloadAction<Action>) => {
      state.actionExecuting = true;
      state.actionError = false;
    },
    actionExecuted: (state, _action: PayloadAction<SignalRAction>) => {
      state.actionExecuting = false;
      state.actionError = false;
    },
    actionNotExecuted: (state, _action: PayloadAction<SignalRAction>) => {
      state.actionExecuting = false;
      state.actionError = true;
    },
  },
});

// Export Actions
export const {
  // Create,
  prepareSignature,
  createVisit,
  visitCreated,
  visitNotCreated,
  // Update
  updateVisitStatus,
  visitStatusNotUpdated,
  visitUpdatedInPacs,
  // Guest
  setGuest,
  // Actions
  executeAction,
  actionExecuted,
  actionNotExecuted,
  // Set trace
  setTraceId,
} = visitsSlice.actions;

// Export Reducer
export default visitsSlice.reducer;
